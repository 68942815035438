/* Style général de la page */
body {
    background-color: #0a0b14;
    color: #ffffff;
}

.staking-area {
    padding: 40px 0;
    background-color: #0a0b14;
    min-height: 100vh;
}

/* Style des cartes */
.card {
    background-color: #1a1b26;
    border: 1px solid #2f3042;
    border-radius: 10px;
}

.card-body {
    padding: 1.5rem;
}

.card-title {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 600;
}

.card-subtitle {
    color: #5d5fef;
}

/* Carte total staked */
.total-staked-card .card-text.large-text {
    font-size: 2rem;
    color: #5d5fef;
    font-weight: bold;
}

/* Formulaires */
.form-control {
    background-color: #2f3042;
    border: 1px solid #3a3b5a;
    color: #ffffff;
}

.form-control:focus {
    background-color: #2f3042;
    border-color: #5d5fef;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem rgba(93, 95, 239, 0.25);
}

.form-control::placeholder {
    color: #a0a0a0;
}

/* Boutons */
.btn {
    padding: 0.5rem 1rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.btn.btn-bordered-white {
    background: transparent;
    border: 2px solid #fff;
    color: white;
}

.btn-primary {
    background-color: #5d5fef;
    border-color: #5d5fef;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #4547e8;
    border-color: #4547e8;
}

.btn-secondary {
    background-color: #3a3b5a;
    border-color: #3a3b5a;
    color: #ffffff;
}

.btn-secondary:hover {
    background-color: #2f3042;
    border-color: #2f3042;
}

.btn-danger {
    background-color: #ef5d5d;
    border-color: #ef5d5d;
    color: #ffffff;
}

.btn-danger:hover {
    background-color: #e84545;
    border-color: #e84545;
}

/* Cartes de stake */
.stake-card {
    background-color: #1a1b26;
    border: 1px solid #2f3042;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.stake-card .card-title {
    color: #ffffff;
}

.stake-details p {
    color: #a0a0a0;
    margin-bottom: 0.5rem;
}

/* Badge */
.badge-success {
    background-color: #4caf50;
    color: white;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    margin-left: 0.5rem;
}

/* Barre de progression */
.progress {
    background-color: #2f3042;
    border-radius: 0.5rem;
    overflow: hidden;
}

.progress-bar {
    background-color: #5d5fef;
    transition: width 0.6s ease;
}

/* Messages popup */
.popup-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
    max-width: 300px;
    width: 90%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    background-color: #1a1b26;
}

.popup-message::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
}

.error-popup .popup-content {
    border-left: 4px solid #ef5350;
}

.success-popup .popup-content {
    border-left: 4px solid #4caf50;
}

.popup-content {
    text-align: center;
    color: #ffffff;
}

.popup-content h4 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #ffffff;
}

.popup-content button {
    background-color: #5d5fef;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.popup-content button:hover {
    background-color: #4547e8;
}

/* Chargement */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    background-color: #1a1b26;
    padding: 20px 40px;
    border-radius: 10px;
    color: #ffffff;
}

/* Utilitaires */
.text-white {
    color: white !important;
}

.text-primary {
    color: #5d5fef !important;
}
.stake-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.stake-actions button {
    flex: 1;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745;
    color: white;
}

.btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-success:disabled {
    background-color: #94d3a2;
    border-color: #94d3a2;
}

.text-success {
    color: #28a745 !important;
}

.font-weight-bold {
    font-weight: bold;
}

.gap-2 {
    gap: 0.5rem;
}

/* Support mobile */
@media (max-width: 768px) {
    .stake-actions {
        flex-direction: column;
        gap: 0.5rem;
    }
    
    .stake-actions button {
        width: 100%;
        margin: 0.25rem 0;
    }
}